<div class="NotesHeader">
    <div class="title">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" [attr.fill]="'#fff'"
            style="margin-right: 4px;">
            <path
                d="M160-240q-33 0-56.5-23.5T80-320v-480q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v720L720-240H160Z" />
        </svg>
        {{title}}<elevate-tooltip [size]="16" [tipData]="tooltips['Discussion']" style="margin-left: 4px; --text-subdued-color: #cccccc"></elevate-tooltip>

        <div class="resolveduserbtn" (click)="onShowAudience()">
            <mat-icon class="tab-icon" [title]="'Show audience'">group</mat-icon>
            <div>Audience</div>
        </div>
    </div>
    <div class="subtitle">{{subtitle}}</div>
</div>
<div #notesControl class="NotesControl" [class.readonly]="asset?.access < 2"></div>
<div *ngIf="asset.access > 2" class="info"><b>Hint: </b>Hover over messages to see actions.</div>
