<div class="modal {{this.themeSvc.theme}}">
    <div class="titlebarctrl">
        <h3 class="title">Audience Details</h3>
        <button
            mat-icon-button
            style="position:absolute; right: 0px;"
            (click)="onClose()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="tableFixHead">
    <table>
        <thead>
            <tr>
                <th><mat-icon [ngx-tooltip]="tooltip">help</mat-icon>
                    <ng-template #tooltip>
                        <div class="tooltipContainer">
                        @for (color of dto.NOTE_ACTIVITY_COLORS; track color; let i = $index) {
                            <div class="row">
                                <div class="color" [style.--color]="color"></div>
                                @if (i == 0) {
                                    <div class="text">Activity as of today.</div>
                                }
                                @else if (i == 1) {
                                    <div class="text">Activity as of  yesterday.</div>
                                }
                                @else {
                                    <div class="text">Activity as of  {{i + 1}} days ago.</div>
                                }
                            </div>
                        }
                        </div>
                    </ng-template>
                </th>
                <th>User Information</th>
                <th>Access</th>
                <th>Belongs To</th>
                <th>Users Elevate Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of audienceData">
                <td>
                        <div class="icon" [child-element]="item['imagedata']"></div>
                </td>
                <td>
                        <div class="txt nametxt">{{item.name}}</div>
                        <div class="txt">{{item.title}}</div>
                        <div class="txt">{{item.personaName}}</div>
                        <div><a href="mailto:{{item.email}}">{{item.email}}</a></div>
                </td>
                <td>
                    <div class="txt">
                        <span [matTooltip]="'User has ' + item.access + ' access'">
                            <img class="accessIcon" src="./assets/img/{{item.accessIcon}}">
                            <span>{{capitalize(item.access)}}</span>
                        </span>
                    </div>
                </td>
                <td>
                        <div class="org txt">Organization:</div>
                        <div *ngIf="!item.companyNames || item.companyNames.length == 0">No organization assigned</div>
                        <div *ngFor="let companyName of item.companyNames">{{companyName}}</div>
                        <div class="groups txt">Assigned Groups:</div>
                        <div *ngIf="!item.groupNamesData || item.groupNamesData.length == 0">Direct assignment</div>
                        <div *ngFor="let belongsToItem of item.groupNamesData">{{belongsToItem}}</div>
                </td>
                <td>
                        <div class="txt">User status: {{item.userStatus}}</div>
                        <div class="notification-icons">
                            Notified by:
                            @if (item?.notificationChannels?.useEmail) {
                            <svg matTooltip="User receives notifications via Email" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24" fill="var(--text-color)" style="margin-right: 4px">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
                            </svg>
                            }
                            @if (item?.notificationChannels?.useSlack) {
                            <svg matTooltip="User receives notifications via Slack" width="24" height="24" viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;">
                                <path
                                    d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
                                    fill="#E01E5A" />
                                <path
                                    d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
                                    fill="#36C5F0" />
                                <path
                                    d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
                                    fill="#2EB67D" />
                                <path
                                    d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
                                    fill="#ECB22E" />
                            </svg>
                            }
                        </div>
                        <div class="txt">Last Seen: {{item.lastLoggedInTime}}</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
