import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DTO } from 'src/dto/dto';
import { HtmlSanitizer } from 'src/app/utils/pipes/html-sanitizer.pipe';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { ChildElementDirective } from 'src/app/directives/child-element.directive';
import { TenantService } from 'src/app/services/tenant.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { DtoService } from 'src/app/services/dto.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { TooltipDirective } from 'src/app/directives/tooltip.directive';


export interface AudienceItem {
    id: number
    parentId: ParentId
    idx: string
    title: string
    name: string
    lastLoggedInTime: string
    userStatus?: string
    access: string
    email: string
    persona: string
    personaName?: string
    personaDescription?: string
    company: string
    groups: string
    notifyDetails: string
    companyNames: string[],
    groupNames: string
    groupNamesData: string[],
    notificationChannels: NotificationDetails,
    accessIcon: string;
    invited: boolean
}

export interface ParentId {

}

export interface Company {
    name: string;
}

export interface Groups {
    name: string;
}

export interface NotificationDetails {
    useEmail: boolean,
    useSlack: boolean
}

const AccessLevels = {
    0: { label: "REVOKED", icon: 'access-revoke.svg' },
    1: { label: "READ-ONLY", icon: 'access-read-only.svg' },
    2: { label: "LIMITED", icon: 'access-limited.svg' },
    4: { label: "READ/WRITE", icon: 'access-read-write.svg' },
    8: { label: "OWNER", icon: 'access-owner.svg' },
    16: { label: "ADMIN", icon: 'access-admin.svg' },
}

@Component({
    selector: 'app-audience',
    standalone: true,
    templateUrl: './audience.component.html',
    styleUrls: ['./audience.component.scss'],
    imports: [
        CommonModule,
        HtmlSanitizer,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        ChildElementDirective,
        MatTooltipModule,
        MatMenuModule,
        TooltipDirective
    ],
})
export class AudienceComponent {

    public audienceData: AudienceItem[] = [];

    // There is an injector issue upstream due to the http client
    // TODO: This should be pulled by DI
    dto = window['dtoService'] as DtoService;

    constructor(
        @Inject(MAT_DIALOG_DATA) public asset: DTO,
        public dialogRef: MatDialogRef<any>,
        public tenantService: TenantService,
        public themeSvc: ThemeLoaderService
    ) {
    }

    async ngOnInit() {
        const endpoint = "api/eda/v1.0/data?name=audience";
        let refType = DTO.getReferenceType(typeof this.asset.dto == "string" ? this.asset.dto : null);
        if( !refType) {
            refType = this.asset.refType;
        }

        const audienceItems: AudienceItem[] = await DTO.http.get(`${endpoint}&refType=${refType}&refId=${this.asset.id}&createdBy=${this.asset.createdBy}`, undefined, true);

        this.audienceData = audienceItems?.map(item => {
            item['imagedata'] = this.getUserImage(item);

            // Get the company names
            const companies = item.company ? JSON.parse(item.company) : [];
            item.companyNames = companies.map(company => company.name);

            // Get the Group names
            item.groupNamesData = item.groupNames?.split(',') || [];

            // Get the notification details
            const notificationDetailsData: NotificationDetails = item.notifyDetails && JSON.parse(item.notifyDetails);
            item.notificationChannels = notificationDetailsData;

            item.lastLoggedInTime = this.tenantService.users.emailMap[item.email]?.lastActive?.toLocaleString() || "(Unknown)";

            // Get the access level.
            const accessItem = AccessLevels[item?.access] || AccessLevels[0];
            item.accessIcon = accessItem?.icon;
            item.access = accessItem?.label;

            return item;
        }) || [];
    }

    getUserImage(item: AudienceItem) {
        const userObject = this.tenantService.users.idMap[item.id];
        return getUserProfileImageElement(userObject, [], "64x64", false, true)
    }

    getAssetImage(asset: DTO) {
        return DTO.getAssetIcon(asset);
    }

    getImage(assetType: number, assetId: number) {
        return DTO.getAssetIcon(assetType, assetId);
    }

    onClose() {
        this.dialogRef.close();
    }

    capitalize(word) { return word.charAt(0).toUpperCase() + word.slice(1); }
}
